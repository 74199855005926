<template>
    <div class="box">
        <div class="not">
            <div class="not-tit">
                <span>重庆市新闻选题管理平台</span>
            </div>
            <!-- <div class="not-simg">
                <img src="../../../assets/image/微信公众号.jpg" alt="">
            </div>
            <div class="not-bimg">
            <img src="../../../assets/image/zt3.jpg" alt="" >
            <img src="../../../assets/image/zt2.jpg" alt="" >
            <img src="../../../assets/image/zt1.jpg" alt="" > -->
            <!-- </div> -->
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;重庆市新闻选题管理平台是根据市委宣传部工作安排，由重庆广大融媒科技有限公司开发，用于实现中央媒体、市属媒体、市委宣传部、市级部门及区县宣传部等单位之间宣传选题的上传下达。重庆市新闻选题管理平台提供了一站式生产平台，可以发布选题计划、上报选题线索，并对选题进行数据统计、分析，让用户工作变得更加便捷、优质、高效。</p>
            <!-- <button class="n-btn" ></button> -->
            <div class="n-btn">
            <a href="https://xtbs.ljsyy.net/user/login/">点击跳转重庆市新闻选题管理平台</a>
            </div>
            <div style="margin-top: 10px;">
                <div class="n-btnd">
                    <div class="n-btndd">
                        <a href="https://www.cqgdrm.com/download/重庆市新闻选题管理平台 v3.0 （媒体）.pdf" target="blank">使用手册（媒体）</a>
                        <a href="https://www.cqgdrm.com/download/重庆市新闻选题管理平台 v3.0 （媒体）.pdf" download="重庆市新闻选题管理平台 v3.0 （媒体）.pdf">
                            <img src="../../../assets/image/下载.png" alt="" width="20">
                        </a>
                    </div>
                    <div >
                        <a href="https://www.cqgdrm.com/download/重庆市新闻选题管理平台 v3.0 （市委宣传部）.pdf" target="blank">使用手册（市委宣传部）</a>
                        <a href="https://www.cqgdrm.com/download/重庆市新闻选题管理平台 v3.0 （市委宣传部）.pdf" download="重庆市新闻选题管理平台 v3.0 （市委宣传部）.pdf">
                            <img src="../../../assets/image/下载.png" alt="" width="20">
                        </a>
                    </div>
                     
                    <div>
                        <a href="https://www.cqgdrm.com/download/重庆市新闻选题管理平台 v3.0 （市级部门及区县宣传部）.pdf" target="blank">使用手册（市级部门及区县宣传部）</a>
                        <!-- <a href="#" onclick="odownload">
                            <img src="../../../assets/image/下载.png" alt="" width="20">
                        </a> -->
                        <a href="https://www.cqgdrm.com/download/重庆市新闻选题管理平台 v3.0 （市级部门及区县宣传部）.pdf" download="重庆市新闻选题管理平台 v3.0 （市级部门及区县宣传部）.pdf">
                            <img src="../../../assets/image/下载.png" alt="" width="20">
                        </a>
                    </div>
            </div>
            </div>
            
          </div>
            
    </div>
</template>
<script>


export default{
    name:"Notice",

    data(){
    return {
            expande: true,
            needShowExpande: false,
    }
  },
  methods: {
        expandeClick() {
            console.log('expandeClick')
            this.expande = !this.expande
        },
        odownload(){
            window.open(`https://www.cqgdrm.com/download/重庆市选题管理系统 v2.0 （市级单位及区县宣传部）.pdf`, '_self')
        }
  },
   mounted() {
        // this.$nextTick(() => {
        //     let lineHeight = 22
        //     if (this.$refs.content.offsetHeight > lineHeight * 3) {
        //         this.expande = false
        //         this.needShowExpande = true
        //     } else {
        //         this.expande = true
        //     }
        // })
    },
};
</script>
<style scoped>
.not{
    float: right;
    width: 320px;
    margin: 0 auto;
    background-color: #fff;
    border: #e8e8e8 solid 1px;
    margin-top: 10px;
    border-top: #e13f38 solid 2px;
    padding: 0 15px 10px;
}
.not .not-tit{
    text-align: center;
    margin-bottom: 10px;
    padding: 10px 0;
    border-bottom: #e8e8e8 solid 1px;
}
.not .not-tit span{
    font-size: 20px;
    display: block;
    /* background: url(../../../assets/image/icotz09.png) no-repeat left center; */
    color: #e13f38;
    padding-left: 15px;
}
.not .not-simg img{
    margin-left: 80px;
    margin-bottom: 30px;
}
.not .not-bimg img{
    width: 290px;
    margin: 10px 10px 10px 0;
}
.n-btn{
    display: block;
    background-color: #e13f38;
    width: 230px;
    height: 35px;
    text-align: center;
    margin: 10px auto;
    padding: 5px 0;
    margin-bottom: 20px;
}
.n-btn a{
    color: #fff;
}
.n-btnd{
    display: block;
    background-color: #e13f38;
    width: 290px;
    height: 100px;
    text-align: left;
    margin: 0 auto;
    padding: 5px 0;
}
.n-btndd{
    margin-top: 10px;
}
.n-btnd a{
    display: inline-block;
    color: #fff;
    margin-left: 10px;
}

</style>