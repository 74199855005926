<template>
  <div class="index-borad-3">
    <!-- <div class="tit">
      <router-link to="/news/publish" class="more">更多</router-link>
      <span>通知公告</span>
    </div> -->
    <div class="list-2">
      <ul>
        <li
          v-for="(item, index) in publishList"
          :key="index"
          :data-index="index"
        >
          {{ item.title
            }}<span>{{
              dateFormat(item.createdTime, "YYYY-MM-DD")
            }}</span>
          <!-- <router-link
            :to="{
              path: `/news/publish/details`,
              query: { id: item.id },
            }"
            :title="item.title"
            >{{ item.title
            }}<span>{{
              dateFormat(item.createdTime, "YYYY-MM-DD")
            }}</span></router-link> -->
        </li>
      </ul>
    </div>
  </div>
  
</template>


<script>
import axios from "@/api/apis";
import dateFormat from "@/utils/dateFormat";

import { onMounted, reactive, toRefs } from "vue";

export default {
  name: "Publish",
  setup() {
    const state = reactive({
      publishList: [], //导航列表数据信息
    });
    const loadPublish = () => {
      axios
        .post("https://cqgdrm.com/api/website/publish/page", {
          pageSize: 10,
          current: 1,
        })
        .then(function (data) {
          state.publishList = data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    onMounted(() => {
      loadPublish();
    });
    return {
      dateFormat,
      ...toRefs(state),
    };
  },
};
</script>

<style scoped>
</style>
