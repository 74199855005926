<template>
  <div class="index-borad-2">
    <div class="tit">
      <a href="" class="more">更多</a> <span>新闻中心</span>
    </div>
    <ul class="list-1">
      <li>
        <a href="122.html" title="市委统战部召开民主党派财务负责人座谈会"
          >市委统战部召开民主党派财务负责人座谈会</a
        >
      </li>
      <li>
        <a href="121.html" title="坚持党政建设意义重大 营造风清气正的政治生态"
          >坚持党政建设意义重大 营造风清气正的政治生态</a
        >
      </li>
      
    </ul>
  </div>
</template>


<script>
export default {
  name: "News",
}; 
</script>

<style scoped>
</style>
