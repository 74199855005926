<template>
  <div class="m_area m_area1">
    
    <div class="box">
      <div class="head_news">
      <a>
        <h3>通知公告</h3>
      </a>
      
      <Publish />
      
      <!-- <Announce /> -->
      <router-link
          to="/news/publish"
          class="u_details"
        >查看详情</router-link>
    </div>
    <div class="bj" style="margin-right:15px;">
        <div class="index-borad-1 swiper-container">
    <swiper
      initialSlide=1
      :autoplay="swiper_options.autoplay"
      :loop="swiper_options.loop"
      :speed="swiper_options.speed"
      :navigation="swiper_options.navigation"
      :pagination="swiper_options.pagination"
      :coverflowEffect="swiper_options.coverflowEffect"
      :centeredSlides="swiper_options.centeredSlides"
      class="swiper-wrapper"
    >
      <swiper-slide
        class="swiper-slide"
        v-for="(item, index) in bannerList"
        :key="index"
        :data-index="index"
      >
        <a
          v-if="item.type != 1"
          :href="item.content"
          target="_blank"
        >
          <div class="img-cover">
            <span
              :style="
                `background-image: url(` + httpFile + item.imageUrl + `);`
              "
              :alt="item.title"
            ></span>
          </div>
          <dl>
            <dt>
              <strong>{{ dateFormat(item.createdTime, "MM/DD") }}</strong>{{ dateFormat(item.createdTime, "YYYY") }}
            </dt>
            <dd>{{ item.title }}</dd>
          </dl>
          </a>
          <router-link
            v-else
            :to="item.content"
            :title="item.title"
          >
            <div class="img-cover">
              <!-- <img src="@/assets/image/test.png" alt=""> -->
              
              <span
                :style="
                `background-image: url(` + httpFile + item.imageUrl + `);`
              "
                :alt="item.title"
              ></span>
            </div>
            <dl>
              <dt>
                <strong>{{ dateFormat(item.createdTime, "MM/DD") }}</strong>{{ dateFormat(item.createdTime, "YYYY") }}
              </dt>
              <dd>{{ item.title }}</dd>
            </dl>
            </router-link>
            </swiper-slide>
            </swiper>
  </div>
    </div>
    
    </div>
    </div>
</template>

<script>
import { onMounted, reactive, toRefs } from "vue";
import axios from "@/api/apis";
import { getFile } from "@/api/http";
import dateFormat from "@/utils/dateFormat";

import SwiperCore, {
  Autoplay,
  Pagination,
  EffectCoverflow,
  Navigation
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

import "swiper/swiper.scss";
import "@/assets/css/pagination.min.css";
import "@/assets/css/navigation.min.css";
import  Publish from "./Publish";

SwiperCore.use([Autoplay, Pagination, EffectCoverflow, Navigation]);

export default {
  name: "Banner",
  components: {
    Swiper,
    SwiperSlide,
    Publish
  },
  setup() {
    const state = reactive({
      httpFile: getFile(),
      bannerList: [],
      swiper_options: {
        autoplay: {
          disableOnInteraction: false, // 鼠标滑动后继续自动播放
          delay: 8000 //8秒切换一次
        },
        speed: 1000, //切换过渡速度
        loop: true,
        slidesPerView: "auto", //解决最后一张切换到第一张中间的空白
        centeredSlides: true, //设置slide居中
        coverflowEffect: {
          // rotate: 0, //slide做3d旋转时Y轴的旋转角度。默认50。
          stretch: 0, //每个slide之间的拉伸值（距离），越大slide靠得越紧。 默认0。
          depth: 100, //slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
          modifier: 1 //depth和rotate和stretch的倍率，相当于            depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显。默认1。
          // slideShadows: false, //开启slide阴影。默认 true。
        },
        navigation: {
          nextElRef: ".index-borad-1 .swiper-button-next .swiper-button-white",
          prevElRef: ".index-borad-1 .swiper-button-prev .swiper-button-white"
        },
        pagination: {
          clickable: true
        }
      }
    });
    const loadBanner = () => {
      axios
        // .post("/banner/list")
        .post("https://cqgdrm.com/api/website/banner/list")
        .then(function(data) {
          state.bannerList = data;
        })
        .catch(function(error) {
          console.log(error);
        });
    };
    onMounted(() => {
      loadBanner();
    });
    return {
      dateFormat,
      ...toRefs(state)
    };
  }
};
</script>

<style scoped>
</style>