<template>
  <!-- ad-1 有问题 -->
  <!-- <div class="img-center" style="margin-top: 10px">
    <a href=""><img src="https://z3.ax1x.com/2021/04/16/cWMZH1.jpg" /></a>
  </div> -->
  <div>
    
  </div>
</template>


<script>
export default {
  name: "Poster",
};
</script>

<style scoped>
</style>
