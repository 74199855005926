<template>
<div>
  <div class="clearfix">
    <Banner />
    <!-- <Swiper/> -->
    <!-- <News /> -->
    <!-- <Publish /> -->
  </div>
  <div class="box">
    <Poster />
  <div class="clearfix col-2">
    <!-- 公司新闻 -->
    <Notice />
    <Module
      v-for="(item, index) in modules"
      :key="index"
      :data-index="index"
      :title="item.title"
      :group="item.group"
      :router="item.router"
    />
    
  </div>
  <!-- <Panel /> -->
  </div>
</div>

</template>

<script>
import { reactive, toRefs } from "vue";
import Banner from "./components/Banner";
import News from "./components/News";
import Publish from "./components/Publish";
import Module from "./components/Module";
import Poster from "./components/Poster";
import Notice from "./components/Notice";
// import Panel from "./components/Panel";
// import Swiper from './components/Swiper.vue';

export default {
  name: "Home",
  components: {
    Banner,
    News,
    Publish,
    Module,
    Poster,
    Notice
    // Swiper
  },
  created() {},
  setup() {
    const state = reactive({
      modules: [
        { title: "公司新闻", group: "1", router: "/news/company" },
        // { title: "员工风采", group: "2", router: "/activity" },
        { title: "党风建设", group: "3", router: "/party" }
      ]
    });
    return {
      ...toRefs(state)
    };
  }
};
</script>

<style scoped>
</style>
