<template>
    <div class="col-item">
      <div class="panel">
      <div class="tit">
        <router-link
          :to="router"
          class="more"
        >更多</router-link>
          <span>{{ title }}</span>
      </div>
      <div class="ttt" style="margin-top: 20px;">
        <ul
        class="list-1"
      >
        <li
          v-for="(item, index) in articleList"
          :key="index"
          :data-index="index"
        >
          <router-link
            v-if="item.type == 1"
            :to="{
              path:
                item.groupId == 1
                  ? `/news/company/details`
                  : item.groupId == 2
                  ? `/news/activity/details`
                  : `/party/details`,
              query: { id: item.id },
            }"
            :title="item.title.replace(`<b>`, ``)"
          >
            <div v-if="item.images.length!==0">
              <div class="img-m">
              <a><img 
              :src="item.images[0]" alt="">
                <!-- <img
                  :src="item.imageUrl"
                /> -->
              </a>
            </div>
            <div style="display:flex; flex-direction:column; justify-content: space-between;">
              <div style="flex: 1;">
                <a class="title-m">{{ item.title }}</a>
              <div :class="['title-describes', expande ? 'expande' : 'close']" ref="title-describes">
                <!-- <div class="title-describes-p"> -->
                  {{item.abstracts}}</div>
                <div class="expande-button-wrap" v-if="needShowExpande">
                <div class="expande-button" @click="expandeClick" v-if="!expande">
                <span style="color: black">...</span> 展开
                </div>
                <div class="expande-button" @click="expandeClick" v-else>收起</div>
                </div>
              </div>
            </div>
              <span style="margin-left: 20px;">{{ dateFormat(item.publishTime, "YYYY-MM-DD") }}</span>
            </div>
            <div v-if="item.images.length==0">
              <a class="title-t">{{ item.title }}</a>
              <div class="title-describes-t">
                {{item.abstracts}}
            </div>
              <span style="display:block; margin-left: 10px;margin-top: 10px;">{{ dateFormat(item.publishTime, "YYYY-MM-DD") }}</span>
            </div>
            
            </router-link>
            
            <a
              v-else
              :href="item.content"
              target="_blank"
              class="trans"
            >
              <a class="title-t">{{`【转载】` + item.title}}</a>
            
            <div class="title-describes-t">
                {{item.abstracts}}
            </div>
              <span style="display:block; margin-left: 10px;margin-top: 10px;">{{ dateFormat(item.publishTime, "YYYY-MM-DD") }}</span>
              </a>
              </li>
              </ul>
      </div>
      
    </div>
  </div>
</template>

<script>
import { onMounted, nextTick, reactive, toRefs } from "vue";
import axios from "@/api/apis";
import dateFormat from "@/utils/dateFormat";
import { getFile } from "@/api/http";


export default {
  name: "Module",
  props: ["title", "group", "router"],
  setup(props) {
    const state = reactive({
      httpFile: getFile(),
      style: false,
      articleList: []
    });

    const loadArticle = () => {
      axios
        .post("https://cqgdrm.com/api/website/article/page", { group: props.group, pageSize: 3, current: 1 })
        .then(function(data) {
          state.articleList = data.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    };
    onMounted(() => {
      loadArticle();
      let viewSize = document.documentElement.clientWidth;
      state.style = isWeiXin() && viewSize < 767;
      /**
       * 判断是否是微信浏览器
       */
      function isWeiXin() {
        //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
        let ua = window.navigator.userAgent.toLowerCase();
        //通过正则表达式匹配ua中是否含有MicroMessenger字符串
        return ua.match(/MicroMessenger/i) == "micromessenger" ? true : false;
      }
    });
    return {
      dateFormat,
      ...toRefs(state),
      props
    };
  }
};
</script>

<style scoped>
</style>
